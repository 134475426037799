<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable camelcase -->
<template>
  <div class="property-search">
    <div class="property-search__filters-container">
      <div class="property-search__label">
        <div class="property-search__label__col1">Filter your search</div>
        <div class="property-search__label__col2">
          <a class="clear" :disabled="loading" :onclick="clear">Reset</a>
        </div>
      </div>

      <ul class="property-search__filters">
        <li>
          <button
            :class="this.selectedStatusID == 0 ? 'active' : ''"
            @click="filterPosts(0)"
          >
            All Developments
          </button>
        </li>

        <li v-for="(category, index) in this.categories" :key="index">
          <button
            :class="this.selectedStatusID == category.id ? 'active' : ''"
            @click="filterPosts(category.id)"
          >
            {{ category.name }}
          </button>
        </li>
      </ul>
    </div>

    <div v-if="total == 0 && !loading" class="no-results">
      Sorry, there are no developments that match the above criteria.
    </div>

    <TransitionGroup
      name="stagged-fade"
      tag="div"
      class="property-search__results property-search__results--top-border"
    >
      <template v-for="(post, index) in posts" :key="index">
        <div class="property-search__post">
          <div class="development-panel animate fade-in">
            <div
              v-if="post.sellingCaption"
              class="development-status"
              :class="post.statusID"
            >
              {{ post.sellingCaption }}
            </div>
            <div v-else class="development-status" :class="post.statusID">
              {{ post.status }}
            </div>

            <div class="development-panel__content">
              <h3>{{ post.title }}</h3>
              <h4>{{ post.location }}</h4>
            </div>

            <img
              v-if="post.imageSrc"
              :src="post.imageSrc"
              :srcset="post.imageSrcset"
              alt=""
            />

            <div class="button-center-container b-top">
              <a :data-index="index" class="button" :href="post.link"
                >Read more</a
              >
            </div>
          </div>
        </div>
      </template>
    </TransitionGroup>

    <div v-if="loading" class="loading-spinner"></div>

    <div class="pagination-wrapper">
      <searchPagination
        v-if="totalPages > 1"
        v-model="params.page"
        :page-count="totalPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="paginationCallback"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination'"
        :page-class="'pagination__item'"
        :next-link-class="'pagination__next'"
        :prev-link-class="'pagination__prev'"
      ></searchPagination>
    </div>
    <div class="pagination-label">
      PAGE {{ this.params.page }} of {{ this.totalPages }}
    </div>
  </div>
</template>

<script>
import SearchPagination from "./SearchPagination.vue";
import { animationsV2 } from "../../animationsv2.js";

export default {
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SearchPagination,
  },
  data() {
    return {
      posts: [],
      categories: [],
      params: {
        page: 1,
        per_page: 21,
        orderby: "menu_order",
        order: "asc",
      },
      selectedStatusID: 0,
      loading: false,
      total: 0,
      totalPages: 0,
      staggerDelay: 50,
    };
  },
  mounted() {
    //console.log("mounted");
    this.getStatuses();

    if (this.config.developmentStatusID) {
      this.selectedStatusID = this.config.developmentStatusID;
    }

    this.getPosts();
  },
  computed: {
    showMore() {
      return this.params.page < this.totalPages || this.loading;
    },
  },
  methods: {
    async getStatuses() {
      this.loading = true;

      await this.axios

        .get("developments_statuses", { per_page: -1 })
        .then((response) => {
          this.categories = response.data;
        });

      this.loading = false;
    },
    async getPosts() {
      this.loading = true;

      if (this.selectedStatusID !== 0) {
        this.params.developments_statuses = this.selectedStatusID;
      } else {
        delete this.params.developments_statuses;
      }

      const params = { ...this.params };

      await this.axios
        .get("developments", {
          params,
        })
        .then((response) => {
          const posts = [];

          response.data.forEach((post) => {
            posts.push({
              link: post.link,
              title: post.title.rendered,
              location: post.location,
              sellingCaption: post.selling_caption,
              status: post.dev_status,
              statusID: post.dev_status_id,
              imageSrc: post.featured_image_src,
              imageSrcset: post.featured_image_srcset,
            });
          });
          this.posts = this.posts.concat(posts);
          this.total = parseInt(response.headers["x-wp-total"], 10);
          this.totalPages = parseInt(response.headers["x-wp-totalpages"], 10);
        });

      animationsV2();
      this.loading = false;
    },
    filterPaginationPosts() {
      this.posts = [];
      this.getPosts();
    },
    filterPosts(statusID) {
      this.posts = [];
      this.params.page = 1;

      this.selectedStatusID = statusID;

      this.params.per_page = 21;
      delete this.params.offset;
      this.getPosts();
    },
    clear() {
      this.params = { ...this.default_params };
      this.filterPosts(0);
    },
    paginationCallback(pageNumber) {
      this.params.page = pageNumber;

      // history.pushState(
      //   this.params.page,
      //   'null',
      //   window.location.pathname + '?pl=' + this.params.page,
      // );

      // // Jump to search results.
      // let top = document.getElementById('results-top').offsetTop;
      // window.scrollTo(0, top - 300);

      this.filterPaginationPosts();
    },
  },
};
</script>
