<template>
  <div class="app">
    <component :is="component" :config="config" />
  </div>
</template>

<script>
import PropertySearch from "./PropertySearch.vue";
import DevelopmentsSearch from "./DevelopmentsSearch.vue";
import NewsSearch from "./NewsSearch.vue";

export default {
  name: "App",
  components: {
    PropertySearch,
    DevelopmentsSearch,
    NewsSearch,
  },
  props: {
    component: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
