<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable camelcase -->
<template>
  <div class="property-search">
    <div v-if="total == 0 && !loading" class="no-results">
      Sorry, there are no news that match the above criteria.
    </div>

    <TransitionGroup
      name="stagged-fade"
      tag="div"
      class="property-search__results"
      @before-enter="beforeEnter($event)"
      @enter="onEnter($event)"
      @leave="onLeave($event)"
    >
      <template v-for="(post, index) in posts" :key="index">
        <div class="property-search__post">
          <div class="post-panel animate fade-in">
            <img
              v-if="post.imageSrc"
              :src="post.imageSrc"
              :srcset="post.imageSrcset"
              alt=""
            />
            <div v-else class="default-image"></div>

            <div class="post-panel__content">
              <h3 v-html="post.title"></h3>
              <div v-html="post.excerpt"></div>
            </div>

            <div class="button-center-container b-top">
              <a :data-index="index" class="button" :href="post.link"
                >Read more</a
              >
            </div>
          </div>
        </div>
      </template>
    </TransitionGroup>

    <div v-if="loading" class="loading-spinner"></div>

    <div class="pagination-wrapper">
      <searchPagination
        v-if="totalPages > 1"
        v-model="params.page"
        :page-count="totalPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="paginationCallback"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination'"
        :page-class="'pagination__item'"
        :next-link-class="'pagination__next'"
        :prev-link-class="'pagination__prev'"
      ></searchPagination>
    </div>
    <div class="pagination-label">
      PAGE {{ this.params.page }} of {{ this.totalPages }}
    </div>
  </div>
</template>

<script>
import SearchPagination from "./SearchPagination.vue";
import { animationsV2 } from "../../animationsv2.js";

export default {
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SearchPagination,
  },
  data() {
    return {
      posts: [],

      params: {
        page: 1,
        per_page: 1,
        orderby: "date",
        order: "desc",
      },
      loading: false,
      total: 0,
      totalPages: 0,
      staggerDelay: 50,
    };
  },
  mounted() {
    this.getPosts();
  },
  computed: {
    showMore() {
      return this.params.page < this.totalPages || this.loading;
    },
  },
  methods: {
    async getPosts() {
      this.loading = true;

      const params = { ...this.params };
      // console.log(params);

      await this.axios
        .get("posts", {
          params,
        })
        .then((response) => {
          const posts = [];

          response.data.forEach((post) => {
            posts.push({
              link: post.link,
              title: post.title.rendered,
              imageSrc: post.featured_image_src,
              imageSrcset: post.featured_image_srcset,
              excerpt: post.excerpt.rendered,
            });
          });
          this.posts = this.posts.concat(posts);
          this.total = parseInt(response.headers["x-wp-total"], 10);
          this.totalPages = parseInt(response.headers["x-wp-totalpages"], 10);
        });

      animationsV2();
      this.loading = false;
    },
    filterPaginationPosts() {
      this.posts = [];
      this.getPosts();
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    onEnter(el) {
      const delay = el.dataset.index * this.staggerDelay;
      setTimeout(() => {
        el.style.opacity = 1;
      }, delay);
    },
    onLeave(el) {
      el.style.display = "none";
    },
    paginationCallback(pageNumber) {
      this.params.page = pageNumber;

      // history.pushState(
      //   this.params.page,
      //   'null',
      //   window.location.pathname + '?pl=' + this.params.page,
      // );

      // // Jump to search results.
      // let top = document.getElementById('results-top').offsetTop;
      // window.scrollTo(0, top - 300);

      this.filterPaginationPosts();
    },
  },
};
</script>
