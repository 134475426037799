import initVue from "./vue/init";
import initMenu from "./main-menu";
import initSliders from "./sliders";
import animationsV2 from "./animationsv2";
import initCookieAccept from "./cookie-accept";

function ready(fn) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

ready(() => {
  initVue();
  initMenu();
  initSliders();
  initCookieAccept();
  animationsV2();

  // Conversion Tracking

  const ptags = document.querySelectorAll("a.conv-phone");

  ptags.forEach((el) =>
    el.addEventListener("click", (event) => {
      //console.log("conversion: " + event.target);
      if (typeof gtag == "function") {
        console.log("event tracked");
        // eslint-disable-next-line no-undef
        gtag("event", "company_phone_number", {
          category: "contact",
          number: event.target,
        });
      }
    }),
  );

  const etags = document.querySelectorAll("a.conv-email");
  etags.forEach((el) =>
    el.addEventListener("click", (event) => {
      //console.log("conversion: " + event.target);
      if (typeof gtag == "function") {
        console.log("event tracked");
        // eslint-disable-next-line no-undef
        gtag("event", "company_email_address", {
          category: "contact",
          number: event.target,
        });
      }
    }),
  );

  //
  // Note this is the script to add into the form conversion pages.
  //

  // <script>
  // if (typeof gtag == 'function') {
  //         console.log('event tracked');
  //         gtag('event', 'form_submission_contact_us', {
  //           category: 'contact',
  //         });
  //       }
  // </script>
});
