/* eslint-disable no-unused-vars */
import Swiper from "swiper";
import {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Thumbs,
} from "swiper/modules";
// import Swiper and modules styles

export default function initSliders() {
  const homeHeroSwiper = new Swiper(".home-hero-slider", {
    modules: [Navigation, EffectFade, Autoplay],
    //    slidesPerView: 1,
    loop: true,
    lazy: true,
    effect: "fade",
    speed: 3000,
    spaceBetween: 0,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    wrapperClass: "home-hero-slider__slides",
    slideClass: "home-hero-slider__slide",
    pagination: false,
    navigation: false,
  });

  const galleryThumbsSwiper = new Swiper(".gallery-thumbs-slider", {
    modules: [Navigation, Pagination, Thumbs],
    spaceBetween: 10,
    slidesPerView: 4,
    centerInsufficientSlides: true,
    freeMode: true,
    lazy: true,
    watchSlidesProgress: true,
    wrapperClass: "gallery-thumbs-slider__slides",
    slideClass: "gallery-thumbs-slider__slide",
    navigation: {
      nextEl: ".gallery-thumbs-slider__nav-button--next",
      prevEl: ".gallery-thumbs-slider__nav-button--prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 6,
      },
    },
  });

  const gallerySwiper = new Swiper(".gallery-slider", {
    modules: [Navigation, Pagination, Thumbs],
    slidesPerView: 1,
    loop: true,
    lazy: true,
    spaceBetween: 0,
    wrapperClass: "gallery-slider__slides",
    slideClass: "gallery-slider__slide",
    navigation: {
      nextEl: ".gallery-slider__nav-button--next",
      prevEl: ".gallery-slider__nav-button--prev",
    },
    thumbs: {
      swiper: galleryThumbsSwiper,
    },
  });

  //console.log(gallerySwiper);

  const genericSliders = document.querySelectorAll(".generic-slider");

  genericSliders.forEach((item) => {
    const caseStudySlider = new Swiper(item, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 500,
      //loop: true,
      lazy: true,
      autoHeight: false,
      wrapperClass: "generic-slider__slides",
      slideClass: "generic-slider__slide",
      pagination: {
        el: ".generic-slider__pagination",
        clickable: true,
        type: "bullets",
        bulletActiveClass: "generic-slider__pagination__bullet--active",
        bulletClass: "generic-slider__pagination__bullet",
        bulletElement: "div",
      },
      navigation: false,
    });
  });

  const factsSliders = document.querySelectorAll(".facts-slider");

  factsSliders.forEach((item) => {
    const factSlider = new Swiper(item, {
      modules: [Autoplay],
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      wrapperClass: "facts-slider__slides",
      slideClass: "facts-slider__slide",
      pagination: false,
      navigation: false,
      breakpoints: {
        900: {
          slidesPerView: 4,
        },
      },
    });
  });

  const quotesSwiper = new Swiper(".quotes-slider", {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    // loop: true,
    // lazy: true,
    //effect: 'fade',
    spaceBetween: 0,
    // autoplay: {
    //   delay: 3500,
    //   disableOnInteraction: false,
    // },
    wrapperClass: "quotes-slider__slides",
    slideClass: "quotes-slider__slide",
    watchOverflow: true,
    pagination: {
      el: ".quotes-slider__pagination",
      clickable: true,
      type: "bullets",
      bulletActiveClass: "quotes-slider__pagination__bullet--active",
      bulletClass: "quotes-slider__pagination__bullet",
      bulletElement: "div",
    },
    navigation: false,
  });
}
