<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable camelcase -->
<template>
  <div class="property-search">
    <div class="property-search__label property-search__label--border-top">
      <div class="property-search__label__col1">Filter your search results</div>
      <div class="property-search__label__col2">
        <a class="clear" :disabled="loading" :onclick="clear">Reset</a>
      </div>
    </div>

    <div class="property-search__form">
      <div class="property-search__form__col">
        <select
          name="bedroom"
          class="category-select"
          v-model="this.selectedBedrooms"
          @change="filterPosts()"
        >
          <option value="0">All Bedrooms</option>
          <option
            v-for="category in options_bedrooms"
            :key="category.value"
            :value="category.value"
          >
            {{ category.name }}
          </option>
        </select>
      </div>

      <div class="property-search__form__col">
        <select
          name="features"
          class="category-select"
          v-model="this.selectedAvailability"
          @change="filterPosts()"
        >
          <option value="0">All Availabilty</option>
          <option
            v-for="category in options_availabilty"
            :key="category.value"
            :value="category.value"
          >
            {{ category.name }}
          </option>
        </select>
      </div>

      <div class="property-search__form__col">
        <select
          name="prices"
          class="category-select"
          v-model="this.selectedPrices"
          @change="filterPosts()"
        >
          <option value="0">All Prices (Max Price)</option>
          <option
            v-for="category in maximum_prices"
            :key="category.value"
            :value="category.value"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="total == 0 && !loading" class="no-results">
      Sorry, there are no properties that match the above criteria.
    </div>

    <TransitionGroup
      name="stagged-fade"
      tag="div"
      class="property-search__results"
    >
      <template v-for="(post, index) in posts" :key="index">
        <div class="property-search__post">
          <div class="property-panel animate fade-in">
            <h3>{{ post.plotTitle }}</h3>
            <div class="building-type">{{ post.buildingType }}</div>
            <div class="status" :class="post.statusID">{{ post.status }}</div>
            <div class="features">
              {{ post.beds }} bedroom<span v-if="post.beds > 1">s</span>
            </div>
            <div v-if="post.statusID == 'status--1'" class="price">
              £{{ post.price }}
            </div>
          </div>
          <a :data-index="index" class="button button--dark" :href="post.link"
            >View Property</a
          >
        </div>
      </template>
    </TransitionGroup>

    <div v-if="loading" class="loading-spinner"></div>

    <div class="pagination-wrapper">
      <searchPagination
        v-if="total > 0"
        v-model="params.page"
        :page-count="totalPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="paginationCallback"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination'"
        :page-class="'pagination__item'"
        :next-link-class="'pagination__next'"
        :prev-link-class="'pagination__prev'"
      ></searchPagination>
    </div>
    <div class="pagination-label">
      PAGE {{ this.params.page }} of {{ this.totalPages }}
    </div>
  </div>
</template>

<script>
import SearchPagination from "./SearchPagination.vue";
import config from "./property-search-form-config.json";
import { animationsV2 } from "../../animationsv2.js";

export default {
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SearchPagination,
  },
  data() {
    return {
      posts: [],

      maximum_prices: config.maximum_prices,
      options_bedrooms: config.bedrooms,
      options_availabilty: config.availabilty,
      params: {
        page: 1,
        per_page: 18,

        // orderby: 'baseprice',
        // order: 'desc',
      },
      selectedBedrooms: "0",
      selectedAvailability: "0",
      selectedPrices: "0",
      bedrooms: "",
      features: "",
      loading: false,
      total: 0,
      totalPages: 0,
      staggerDelay: 50,
    };
  },
  mounted() {
    if (this.config.developmentID) {
      this.params.development_id = this.config.developmentID;
      //console.log(this.params);
    }

    this.getPosts();
  },
  computed: {
    showMore() {
      return this.params.page < this.totalPages || this.loading;
    },
  },
  methods: {
    async getPosts() {
      this.loading = true;

      if (this.selectedBedrooms !== "0") {
        this.params.nsm_plot_bedrooms = this.selectedBedrooms;
      } else {
        delete this.params.nsm_plot_bedrooms;
      }

      if (this.selectedAvailability !== "0") {
        this.params.nsm_plot_status = this.selectedAvailability;
      } else {
        delete this.params.nsm_plot_status;
      }

      if (this.selectedPrices !== "0") {
        this.params.nsm_plot_prices = this.selectedPrices;
      } else {
        delete this.params.nsm_plot_prices;
      }

      if (this.config.developmentID) {
        this.params.development_id = this.config.developmentID;
      }

      const params = { ...this.params };

      await this.axios
        .get("hbp_plots", {
          params,
        })
        .then((response) => {
          const posts = [];

          response.data.forEach((post) => {
            posts.push({
              link: post.link,
              plotTitle: post.plot_title,
              title: post.title.rendered,
              features: post.features,
              beds: post.beds,
              price: post.price,
              statusID: post.status_id,
              status: post.status,
              houseType: post.house_type,
              buildingType: post.building_type,
            });
          });
          this.posts = this.posts.concat(posts);
          this.total = parseInt(response.headers["x-wp-total"], 10);
          this.totalPages = parseInt(response.headers["x-wp-totalpages"], 10);
        });

      animationsV2();
      this.loading = false;
    },

    filterPaginationPosts() {
      this.posts = [];
      this.getPosts();
    },
    filterPosts() {
      this.posts = [];
      this.params.page = 1;

      this.params.per_page = 12;
      delete this.params.offset;
      this.getPosts();
    },
    clear() {
      this.selectedBedrooms = "0";
      this.selectedAvailability = "0";
      this.selectedPrices = "0";
      this.params = { ...this.default_params };
      this.filterPosts();
    },
    paginationCallback(pageNumber) {
      this.params.page = pageNumber;

      // history.pushState(
      //   this.params.page,
      //   'null',
      //   window.location.pathname + '?pl=' + this.params.page,
      // );

      // // Jump to search results.
      // let top = document.getElementById('results-top').offsetTop;
      // window.scrollTo(0, top - 300);

      this.filterPaginationPosts();
    },
  },
};
</script>
